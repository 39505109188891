.statusbar-company-wrapper.statusbar-company-offset {
    height: 68px;
}
.statusbar-company-wrapper {
    margin-top: 3px;
    height: 34px;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: rgb(245, 245, 245);
}

.statusbar-company {
    font-size: 13px;
    height: 34px;
    line-height: 34px;
    border-top: 3px solid var(--bg-blue);
    display: flex;
    flex-direction: row
}

.statusbar-company-tab {
    border-right: 1px solid #ddd;
    padding: 0 10px;
    color: rgb(85, 85, 85)
}

.statusbar-company-tab a {
    text-decoration: none;
    color: rgb(85, 85, 85);
}

.statusbar-company-tab:last-child {
    align-self: flex-end;
    flex: 1;
    text-align: right;
}

.statusbar-company-tab:last-child a {
    font-size: 125%;
    font-weight: bold;
}
